var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row m-0 p-0", attrs: { id: "rule-config" } },
    [
      _c("div", { staticClass: "col m-0 p-0" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            { staticClass: "col ", staticStyle: { "z-index": "999" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "row no-gutters card-body rule-route-display justify-content-between"
                },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.rule
                      ? _c("form", [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c("div", [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      "Rule ID: " + _vm._s(_vm.rule_id_) + " "
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "CBadge",
                                      {
                                        staticClass: "m-1 p-2",
                                        attrs: {
                                          color: _vm.enabled_
                                            ? "success"
                                            : "warning"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.enabled_
                                                  ? "enabled"
                                                  : "disabled"
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  { staticClass: "subsection-label" },
                                  [_vm._v("Name")]
                                ),
                                _c("CInput", {
                                  attrs: {
                                    value: _vm.name_,
                                    isValid: !_vm.$v.name_.$invalid,
                                    invalidFeedback: _vm.invalidFeedback(
                                      "name_"
                                    )
                                  },
                                  on: {
                                    "update:value": function($event) {
                                      _vm.name_ = $event
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  { staticClass: "subsection-label" },
                                  [_vm._v("Description")]
                                ),
                                _c("CTextarea", {
                                  attrs: {
                                    value: _vm.description_,
                                    isValid: !_vm.$v.description_.$invalid,
                                    invalidFeedback: _vm.invalidFeedback(
                                      "description_"
                                    )
                                  },
                                  on: {
                                    "update:value": function($event) {
                                      _vm.description_ = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "form-group",
                              attrs: { id: "trigger-config" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between section-title"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "section-title-text" },
                                    [_vm._v("Trigger Configuration")]
                                  ),
                                  _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        variant: "outline",
                                        color: "info",
                                        hidden: ""
                                      },
                                      on: { click: _vm.logicEditClicked }
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-settings" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c("CCol", { attrs: { col: "2" } }, [
                                        _c("b", [_vm._v(" Devices ")])
                                      ]),
                                      _c(
                                        "CCol",
                                        { attrs: { col: "10" } },
                                        _vm._l(
                                          _vm.selectedDeviceGroups,
                                          function(deviceGroup) {
                                            return _c(
                                              "span",
                                              { key: deviceGroup.uid },
                                              [
                                                _c(
                                                  "CBadge",
                                                  {
                                                    staticClass: "m-1 p-2",
                                                    attrs: { color: "info" }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            deviceGroup.name
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CRow",
                                    [
                                      _c("CCol", { attrs: { col: "2" } }, [
                                        _c("b", [_vm._v(" Topic ")])
                                      ]),
                                      _c(
                                        "CCol",
                                        { attrs: { col: "10" } },
                                        [
                                          _c(
                                            "BadgeLayout",
                                            {
                                              attrs: {
                                                badgeType: "ruleTopic",
                                                content: _vm.topics_
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.topics_) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CRow",
                                    [
                                      _c("CCol", { attrs: { col: "2" } }, [
                                        _c("b", [_vm._v(" Logic ")])
                                      ]),
                                      _c("CCol", { attrs: { col: "10" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.logicNaturalLanguage_) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _vm.alert_uid_
                                ? _c("ActionConfig", {
                                    attrs: {
                                      currUID: _vm.alert_uid_,
                                      flag_update: _vm.flag_update
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }