<template>
  <CBadge :color="color" :class="badgeType">
    <slot></slot>
  </CBadge>
</template>

<style scoped>
.ruleTopic {
    display: inline-block;
    height: 20px;
    margin: 5px;
    padding: 5px;
}
</style>

<script>

const colorChart = {
    ruleTopic: {
        Wand: "info",
        Sensors: "primary",
    }
}

export default {
  name: "BadgeLayout",
  props: {
    badgeType: String,
    content: String,
  },
  computed: {
    color() {
        return colorChart[this.badgeType][this.content];
    },
  },
};
</script>
