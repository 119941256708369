import { render, staticRenderFns } from "./BellBadge.vue?vue&type=template&id=4fc79348&scoped=true&"
import script from "./BellBadge.vue?vue&type=script&lang=js&"
export * from "./BellBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc79348",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\khol.haggerty\\Perforce\\khol.haggerty_AARCOMM-LT-026_323\\Aarcomm\\Products\\IoT Data Logger\\Customer Projects\\Clean Harbors Hydrovac\\Code\\clean-harbors-hydrovac-demo\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fc79348')) {
      api.createRecord('4fc79348', component.options)
    } else {
      api.reload('4fc79348', component.options)
    }
    module.hot.accept("./BellBadge.vue?vue&type=template&id=4fc79348&scoped=true&", function () {
      api.rerender('4fc79348', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/rules/BellBadge.vue"
export default component.exports