var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex justify-content-between" }, [
    _c(
      "div",
      { attrs: { id: "input-wrapper" } },
      [
        _c("vue-tel-input", {
          attrs: { defaultCountry: "CA", onlyCountries: _vm.countries },
          scopedSlots: _vm._u([
            {
              key: "arrow-icon",
              fn: function() {
                return [_c("span", [_vm._v(" +1 ")])]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.content_,
            callback: function($$v) {
              _vm.content_ = $$v
            },
            expression: "content_"
          }
        }),
        _vm.$v.content_.$invalid
          ? _c("small", { attrs: { id: "error-message" } }, [
              _vm._v("Please input a valid phone number.")
            ])
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "CButtonGroup",
          [
            _c("CButton", { on: { click: _vm.onCancelClicked } }, [
              _vm._v(" Cancel ")
            ]),
            _c(
              "CButton",
              {
                attrs: { color: "success", disabled: _vm.$v.content_.$invalid },
                on: { click: _vm.onConfirmClicked }
              },
              [_vm._v(" Confirm ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }