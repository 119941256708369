var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "12" } },
        [
          _c(
            "CardLayout",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("CIcon", { attrs: { name: "cil-bell" } }),
                      _vm._v(" Rule Engine ")
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              [
                _c(
                  "CRow",
                  [
                    _c(
                      "CCol",
                      { attrs: { xs: "5", sm: "5" } },
                      [
                        _c("CInput", {
                          attrs: {
                            placeholder: "Search for a Rule",
                            value: _vm.searchValue,
                            lazy: ""
                          },
                          on: {
                            "update:value": function($event) {
                              _vm.searchValue = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _c(
                                    "CButton",
                                    { attrs: { color: "info" } },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-magnifying-glass" }
                                      }),
                                      _vm._v(" Search ")
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "CRow",
                  [
                    _c(
                      "CCol",
                      { attrs: { lg: "12", md: "12" } },
                      [
                        _c("RuleTable", {
                          staticClass: "h-100",
                          attrs: {
                            filteredRulesUIDs: _vm.filteredRulesUIDs,
                            searchValue: _vm.searchValue
                          },
                          on: { configureClicked: _vm.setActiveUID }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        ],
        1
      ),
      _c("CModal", {
        attrs: {
          show: _vm.activeRuleUID,
          centered: "",
          fade: "",
          color: "",
          "border-color": "secondary",
          title: "Configure Rule",
          size: "lg"
        },
        on: {
          "update:show": function($event) {
            _vm.activeRuleUID = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body-wrapper",
            fn: function() {
              return [
                _vm.activeRuleUID
                  ? _c("RuleConfig", {
                      staticClass: "m-0 p-0",
                      attrs: {
                        options: _vm.options,
                        activeUID: _vm.activeRuleUID,
                        flag_update: _vm.flag_updateRule
                      },
                      on: {
                        validationStatusChanged: _vm.ruleValidationStatusChanged
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("CButton", { on: { click: _vm.onModalCanceled } }, [
                      _vm._v(" Cancel ")
                    ]),
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "success",
                          disabled: !_vm.ruleConfig_validated
                        },
                        on: { click: _vm.onModalConfirmed }
                      },
                      [_vm._v(" Save ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "CToaster",
        { attrs: { autohide: 2500 } },
        [
          _vm._l(_vm.fixedToasts, function(toast) {
            return [
              _c(
                "CToast",
                {
                  key: "toast" + toast,
                  attrs: {
                    show: true,
                    color: "info",
                    header: "Action Cannot Be Completed "
                  }
                },
                [
                  _vm._v(
                    " Sorry, You cannot Add a new Rule because Rule Engine is in "
                  ),
                  _c("strong", [_vm._v(" Preview Mode Only ")])
                ]
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }