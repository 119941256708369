var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recipient-card" },
    [
      !_vm.isActive
        ? _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("div", [
              _c(
                "b",
                { staticClass: "phone-number", attrs: { id: "content" } },
                [_vm._v(_vm._s(_vm.content_))]
              )
            ]),
            _c(
              "div",
              [
                !_vm.blockedEdit
                  ? _c(
                      "CButtonGroup",
                      [
                        _c(
                          "CButton",
                          {
                            attrs: { variant: "outline", color: "danger" },
                            on: { click: _vm.onDeleteClicked }
                          },
                          [_c("CIcon", { attrs: { name: "cil-trash" } })],
                          1
                        ),
                        _c(
                          "CButton",
                          {
                            attrs: { variant: "outline", color: "info" },
                            on: { click: _vm.onEditClicked }
                          },
                          [
                            _c("CIcon", { attrs: { name: "cil-pencil" } }),
                            _vm._v("Edit ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.isActive
        ? _c("RecipientInput", {
            attrs: { content: _vm.content_ },
            on: { confirm: _vm.onConfirmClicked, cancel: _vm.onCancelClicked }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }