<template>
  <div class="items-list">
    <RecipientCard v-for="(value, uid) in recipientsList_" :key="uid"
      class="item"
      :blockedEdit="hasActiveEditOrAdd"
      :uid="uid"
      :content="value.content"
      :isActive="activeEditUID == uid"
      @select="onEditSelect(uid)"
      @confirm="(newContent) => onEditConfirm(uid, newContent)"
      @cancel="onEditCancel"
      @delete="onDeleteConfirm(uid)"
    />
    <span v-if="!hasRecipients"> No Text Recipient At The Moment. </span>
    <RecipientCard
      v-if="isAdding"
      class="item"
      :isActive="true"
      :content="``"
      @confirm="onAddConfirm"
      @cancel="onAddCancel"
    />
    <div class="d-flex justify-content-end item">
      <CButton v-if="!hasActiveEditOrAdd" @click="onAddSelect" color="success" class="float-right"> Add </CButton>
    </div>
    <!-- <Tooltip> "Duplicated" </Tooltip> -->
  </div>
</template>

<style scoped>

.items-list .item{
  margin: 5px 0px;
  padding: 10px;
  border-radius: 5px;
}
</style>

<script>
import RecipientCard from "@/components/rules/RecipientCard"

export default {
  name: "RecipientsList",
  components: {
    RecipientCard,
  },
  data() {
    return {
      activeEditUID: null,
      isAdding: false,
    };
  },
  props: {
    recipientsList_: Object
  },
  computed: {
    hasActiveEditOrAdd () {
      return this.isAdding || this.activeEditUID;
    },

    hasRecipients () {
      return Object.keys(this.recipientsList_).length;
    }
  },
  methods: {
    onEditSelect (uid) {
      this.activeEditUID = uid;
    },

    onEditCancel () {
      this.activeEditUID = null;
    },

    onEditConfirm (uid, newContent) {
      // another option is to send an api request to update the list right away.
      if (this.hasDuplication()) {
        // todo
        return;
      }
      this.$emit("editRecipient", uid, newContent);
      this.activeEditUID = null;
    },

    onAddSelect () {
      this.isAdding = true;
    },

    onAddConfirm (content) {
      if (this.hasDuplication()) {
        // todo
        return;
      }
      this.isAdding = false;
      const uid = this.generateUID();
      this.$emit("addRecipient", uid, content);
    },

    onAddCancel () {
      this.isAdding = false;
    },

    onDeleteConfirm (uid) {
      this.$emit("deleteRecipient", uid); // in the future, the list item id would be the contact UID.
    },

    generateUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
      });
    },

    hasDuplication () { // todo
      return false;
    }
  },
};
</script>