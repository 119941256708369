var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    {
      staticClass: "mb-0 rule-widget-main",
      staticStyle: { "min-width": "200px" }
    },
    [
      _c(
        "CRow",
        { staticClass: "no-gutters" },
        [
          _c(
            "CCol",
            {
              staticClass: "border-right rule-info-container",
              attrs: { sm: "12", md: "3", xs: "12" }
            },
            [
              _c("CCardBody", { attrs: { color: "" } }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c("h5", {}, [_vm._v(_vm._s(_vm.name_))])
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "CBadge",
                      {
                        staticClass: "rule-topic",
                        attrs: { color: _vm.ruleTopicColor }
                      },
                      [_vm._v(" " + _vm._s(_vm.topics_) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex mt-1" },
                  [
                    _c("CSwitch", {
                      staticClass: "mt-1",
                      attrs: {
                        variant: "3d",
                        type: "checkbox",
                        color: "info",
                        "v-c-tooltip": _vm.alertEnabled_
                          ? "Click to Mute Alerts"
                          : "Click to Enable Alerts",
                        checked: _vm.alertEnabled_
                      },
                      on: {
                        "update:checked": [
                          function($event) {
                            _vm.alertEnabled_ = $event
                          },
                          _vm.updateAlertEnableField
                        ]
                      }
                    }),
                    _c("BellBadge", { attrs: { enabled: _vm.alertEnabled_ } })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "align-right pt-2",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "align-right flex-item",
                        attrs: { variant: "outline", color: "info" },
                        on: { click: _vm.configureClicked }
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-settings" } }),
                        _vm._v(" Configure ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "CCol",
            {
              staticClass: "border-right",
              attrs: { xs: "12", sm: "12", md: "9", lg: "9" }
            },
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    { staticClass: "no-gutters justify-content-between" },
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "rule-route d-flex flex-column",
                          attrs: { xs: "4", sm: "4", md: "4" }
                        },
                        [
                          _c("div", { staticClass: "info" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.applyToNDeviceGroups))
                            ]),
                            _c("br"),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Description: " + _vm._s(_vm.description_) + " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "rule-orb mt-auto" }, [
                            _c("img", {
                              staticClass: "rounded-circle border border-info",
                              attrs: {
                                src: "images/lightning-bolt-100.png",
                                alt: "..."
                              }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "rule-route d-flex flex-column",
                          attrs: { xs: "4", sm: "4", md: "4" }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.logicNaturalLanguage_) + " "
                            )
                          ]),
                          _c("div", { staticClass: "rule-orb mt-auto" }, [
                            _c("img", {
                              staticClass: "rounded-circle border border-info",
                              attrs: {
                                src: "images/process-96.png",
                                alt: "..."
                              }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "rule-route d-flex flex-column",
                          attrs: { xs: "4", sm: "4", md: "4" }
                        },
                        [
                          _c("div", { staticClass: "info" }, [
                            _c("span", [
                              _vm._v(
                                " Notification Method: " +
                                  _vm._s(_vm.alertType_)
                              )
                            ]),
                            _c("br"),
                            _c("span", [
                              _vm._v(
                                " # of recipients: " +
                                  _vm._s(_vm.alertRecipients_.length)
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "rule-orb mt-auto" }, [
                            _c("img", {
                              staticClass: "rounded-circle border border-info",
                              attrs: { src: _vm.alertActionImg, alt: "..." }
                            })
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-none d-sm-block",
                      staticStyle: { "margin-top": "-40px", width: "80%" }
                    },
                    [
                      _c("hr", {
                        staticStyle: {
                          height: "2px",
                          "margin-left": "100px",
                          "border-width": "0",
                          color: "gray",
                          "background-color": "gray"
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }