<template>
  <div>
    <CFormLabel class="subsection-label"
    label="Message"
    :tooltip="tooltipText"
    >
    </CFormLabel>
    <CColl></CColl>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="messageMode" id="defaultMsg" value="default" @click="messageRadioOnClick" :checked="!customMessageMode_">
      <label class="form-check-label" for="defaultMsg">
        {{defaultMessageText}} <strong class="strong-text">(Recommended)</strong>
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="messageMode" id="customMsg" value="custom" @click="messageRadioOnClick" :checked="customMessageMode_">
      <label class="form-check-label" for="customMsg">
        Send Your Own Custom Message
      </label>
    </div>
    <CTextarea
      v-if="customMessageMode_"
      placeholder="Enter a Brief Alert Message..."
      @input="textboxOnInput"
      horizontal
      :value="message_"
      rows="2"
      :isValid="isMessageValid"
      :invalidFeedback="invalidFeedback"
    />
  </div>
</template>

<style scoped>
.strong-text{
  color : var(--dark);
}
</style>

<script>
import CFormLabel from '../base/CFormLabel.vue';

const MessageMode = {
  DEFAULT: "default",
  CUSTOM: "custom"
}

const DEFAULT_MESSAGE_TEXT="Server Generated Message"; 
const defaultTooltip =`By selecting ${DEFAULT_MESSAGE_TEXT}, the server auto generates Alert Messages indicating the condition and truck number.   eg: Wand Bypassed on Truck 714722`; 
const customMessageTooltip = "By selecting Custom you can send your own custom Message in the alert"; 



export default {
  name: "AlertMessageEditor",
  components: {

    CFormLabel
},
  props: {
    message_: String,
    invalidFeedback: String,
    customMessageMode_: Boolean,
  },
  computed: {
    isMessageValid () {
      return !this.invalidFeedback;
    } , 
    tooltipText(){
        if(this.customMessageMode_){
          return customMessageTooltip; 
        }
        else{
          return defaultTooltip; 
        }
    }
  },
  data() {
    return {  
      defaultMessageText: DEFAULT_MESSAGE_TEXT, 
      tooltip: ()=>defaultTooltip, 
    };
  },
  methods: {
    messageRadioOnClick (e) {
      this.$emit("customMessageModeChanged", e.target.value == MessageMode.CUSTOM);
    },

    textboxOnInput(val) {
      this.$emit("messageChanged", val);
    }
  },
};
</script>