<template>
  <div class="row m-0 p-0" id="rule-config">
    <div class="col m-0 p-0">
      <div class="row no-gutters">
        <div class="col " style="z-index:999">
          <div
            class="row no-gutters card-body rule-route-display justify-content-between"
          >
            <div class="col-12" >
              <form v-if="rule">
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <div>
                      <span class="label">Rule ID: {{rule_id_}} </span>
                    </div>
                    <div>
                      <CBadge 
                        class="m-1 p-2"
                        :color="enabled_? `success`: `warning`"
                      >
                        <span> {{enabled_? `enabled`: `disabled`}} </span>
                      </CBadge>
                    </div>
                  </div>
                  <div> 
                    <label class="subsection-label">Name</label>
                    <CInput
                      :value.sync="name_"
                      :isValid="!$v.name_.$invalid"
                      :invalidFeedback="invalidFeedback('name_')"
                    />
                  </div>
                  <div> 
                    <label class="subsection-label">Description</label>
                    <CTextarea
                      :value.sync="description_"
                      :isValid="!$v.description_.$invalid"
                      :invalidFeedback="invalidFeedback('description_')"
                    />
                  </div>
                </div>
                <div class="form-group" id="trigger-config">
                  <div class="d-flex justify-content-between section-title">
                    <span class="section-title-text">Trigger Configuration</span>
                    <CButton
                      variant="outline"
                      color="info"
                      @click="logicEditClicked"
                      hidden
                    >
                      <CIcon name="cil-settings"></CIcon>&nbsp;
                    </CButton>
                  </div>
                  <div>        
                    <CRow>
                      <CCol col="2">
                        <b> Devices </b>
                      </CCol>
                      <CCol col="10">
                        <span v-for="deviceGroup in selectedDeviceGroups" :key="deviceGroup.uid">
                          <CBadge color="info" class="m-1 p-2"> 
                            <span> {{deviceGroup.name}} </span>
                          </CBadge>
                        </span>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="2">
                        <b> Topic </b>
                      </CCol>
                      <CCol col="10">
                        <BadgeLayout badgeType="ruleTopic" :content="topics_">
                          {{topics_}}
                        </BadgeLayout>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="2">
                        <b> Logic </b>
                      </CCol>
                      <CCol col="10">
                        {{logicNaturalLanguage_}}
                      </CCol>
                    </CRow>
                  </div>
                </div>
                <div class="form-group">
                  <ActionConfig
                    v-if="alert_uid_"
                    :currUID="alert_uid_"
                    :flag_update="flag_update"
                  ></ActionConfig>
                </div>
              </form>
            </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<style scoped>


.rule-orb {
  height: 50px;
  text-align: left;
}

.rule-orb img {
  background-color: var(--light-gray);
  border-color: "border-info";
}

.rule-widget-container {
  border-radius: 5px;
  width: 100%;
}

.rule-widget-container {
  column-count: 1;
}

.rule-widget-main {
  min-width: 150px;
}

.rule-widget {
  min-width: 100px;
}

.rule-route-display {
  background-color: var(-light);
}

#rule-tool-panel {
  padding: 5px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

.timeline-label{
  z-index: -1;
  
}

ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 1; /* 400*/
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
  height: 150px;
}

ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid var(--info);
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400; /* 400*/
}

#rule-config >>> .section-title {
  height: 34px;
}

#rule-config >>> .section-title-text {
  font-size: 18px;
  font-weight: 20pt;
}

#rule-config >>> .subsection-label {
  font-weight: 500;
}

</style>


<script>
import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapGetters } from 'vuex'
import BadgeLayout from "@/layouts/BadgeLayout";

import ActionConfig from "@/components/rules/ActionConfig"

import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
} from "vuelidate/lib/validators";

const MAX_LENGTH = {
  name_: 60,
  description_: 300,
}

export default {
  name: "RuleConfig",
  components: {
    ActionConfig,
    BadgeLayout,
  },
  props: {
    activeUID: String,
    flag_update: Number,
  },

  data() {
    return {
      uid_: null,
      rule_id_: null,
      enabled_ : null,
      name_: null,
      description_ : null,
      topics_: null,
      alert_uid_: null,
      deviceGroups_uids_: [],
      logics_: null, // these fields correspond to the getters in rule_model, isolated from the actual db structure.
      logicNaturalLanguage_: null,
    };
  },
  computed: {
    ...mapGetters({
      deviceGroups: 'deviceGroups',
    }),

    rules () { // todo: should prevent switching branch on certain views. Also all views should not assume the activeUID is in the list of rules. 
      try {
        return this.$store.getters["rules"].filter(rule => {
          return rule.branch_id == this.$store.getters.selectedBranch.branch_id;
        })
      } catch (err) {
        return [];
      }
    },

    validated() {
      return !this.$v.$invalid;
    },

    rule () {
      return this.rules.find(item => {
        return item.uid == this.activeUID;
      })
    },

    selectedDeviceGroups () {
      return this.deviceGroups.filter(deviceGroup => {
        return this.deviceGroups_uids_.includes(deviceGroup.uid);
      })
    }
  },
  methods: {
    populateFields() {
      if (!this.rule) {
        return;
      }
      this.uid_ = this.rule.uid;
      this.rule_id_ = this.rule.rule_id;
      this.enabled_ = this.rule.enabled;
      this.name_ = this.rule.name;
      this.description_ = this.rule.description;
      this.topics_ = this.rule.topics;
      this.alert_uid_ = this.rule.alert_uid;
      this.deviceGroups_uids_ = this.rule.deviceGroups_uids || [];
      this.logics_ = this.rule.logics;
      this.logicNaturalLanguage_ = this.rule.logicNaturalLanguage;
    },

    async submit() {
      console.log("API action: PUT Rule")
      await this.$app.modifyRule(this.activeUID, {
        enabled: this.enabled_,
        name: this.name_,
        description: this.description_,
      });
    }, 

    // setters for those not using v-model
    setDeviceGroupsUIDs(uids) {
      this.deviceGroups_uids_ = uids;
    },

    invalidFeedback (fieldName) {
      if (!this.$v[fieldName].$invalid) {
        return '';
      }
      if (this.$v[fieldName].required == false) {
        return "Cannot be empty";
      } else if (this.$v[fieldName].email == false) {
        return `Must be an email`;
      } else if (this.$v[fieldName].maxLengthValue == false) {
        return `Cannot exceed length of ${MAX_LENGTH[fieldName]}`;
      } else {
        console.log("Need to handle this error case: ", this.$v[fieldName]);
        return 'Error';
      }
    },

    logicEditClicked () {
      console.log("RuleConfig:: Logic Edit Clicked (Unhandled)")
    }
  },

  watch: {
    rule: {
      immediate: true,
      handler () {
        this.populateFields();
      }
    },

    flag_update: {
      immediate: false,
      handler () {
        this.submit();
      }
    },

    validated: {
      immediate: true,
      handler () {
        this.$emit("validationStatusChanged", this.validated);
      }
    }
  },

  validations: {
    name_: {
      required,
      maxLengthValue: maxLength(MAX_LENGTH.name_),      
    },
    description_: {
      maxLengthValue: maxLength(MAX_LENGTH.description_)
    },
  },
  mixins: [validationMixin],
};
</script>