<template>
  <div class="recipient-card">
    <div class="d-flex justify-content-between" v-if="!isActive">
      <div>
        <b id="content" class="phone-number">{{content_}}</b>
      </div>
      
      <div>
        <CButtonGroup v-if="!blockedEdit">
          <CButton variant="outline" color="danger" @click="onDeleteClicked"> 
            <CIcon name="cil-trash"></CIcon></CButton>
          <CButton  variant="outline" color="info" @click="onEditClicked"> <CIcon name="cil-pencil">
            </CIcon>Edit 
            </CButton>
        </CButtonGroup>
      </div>
    </div>

    <RecipientInput 
      v-if="isActive" 
      :content="content_"
      @confirm="onConfirmClicked" 
      @cancel="onCancelClicked"
    />
  </div>
</template>

<style scoped>
.recipient-card {
  background: rgb(247, 247, 242);
}

.recipient-card:hover {
  background-color: var(--light-gray);
}

#content {
  font-size: 16px;
}
</style>

<script>
import RecipientInput from '@/components/rules/RecipientInput';
export default {
  name: "RecipientCard",
  components: {
    RecipientInput,
  },
  data() {
    return {
      editing: false,
      content_: "",
    };
  },
  props: {
    uid: String,
    content: String, 
    blockedEdit: Boolean,
    isActive: Boolean,
  },
  methods: {
    populateFields () {
      this.content_ = this.content;
    },
    onEditClicked () {
      this.$emit("select");
    },
    onConfirmClicked (content) {
      this.$emit("confirm", content);
    },
    onCancelClicked () {
      this.$emit('cancel');
    },
    onDeleteClicked () {
      this.$emit("delete");
    }
  },
  watch: {
    content: {
      immediate: true,
      handler () {
        this.populateFields();
      }
    },
  },
};
</script>