<template>
  <div class="d-flex justify-content-between">
    <div id="input-wrapper">
      <vue-tel-input 
        v-model="content_"
        defaultCountry="CA"
        :onlyCountries="countries"
      >
      <template v-slot:arrow-icon>
        <span> +1 </span>
      </template>
      </vue-tel-input>
      <small
        v-if="$v.content_.$invalid"
        id="error-message"
      >Please input a valid phone number.</small>
    </div>
    
    <div>
      <CButtonGroup>
        <CButton @click="onCancelClicked"> Cancel </CButton>
        <CButton @click="onConfirmClicked" color="success" :disabled="$v.content_.$invalid"> Confirm </CButton>
      </CButtonGroup>
    </div>
  </div>
</template>

<style scoped>
#error-message {
  color: red;
}
</style>

<script>
const isPhoneNumber = phoneNumber => {
  return /^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/.test(phoneNumber);
}

import { validationMixin } from "vuelidate";

export default {
  name: "RecipientInput",
  mixins: [validationMixin],
  components: {
  },
  data() {
    return {
      content_: "",
      countries: ["CA"],
    };
  },
  props: {
    content: String,
  },
  methods: {
    populateFields () {
      this.content_ = this.content;
    },
    onConfirmClicked () {
      this.$emit("confirm", this.content_);
    },
    onCancelClicked () {
      this.$emit('cancel');
    },
  },
  watch: {
    content: {
      immediate: true,
      handler () {
        this.populateFields();
      }
    },
  },
  validations: {
    content_: {
      isPhoneNumber,
    },
  }
};
</script>