var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "m-0" },
    [
      _c(
        "CCardBody",
        [
          _c("CDataTable", {
            staticStyle: { "font-size": "14px" },
            attrs: {
              items: _vm.tableItems,
              fields: _vm.fields,
              "items-per-page": 20,
              hover: "",
              pagination: "",
              cleaner: false,
              striped: true,
              "table-filter": false,
              tableFilterValue: _vm.searchValue,
              sorter: ""
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("RuleCard", {
                      key: item.uid,
                      staticClass: "w-100 mb-2",
                      attrs: { currUID: item.uid },
                      on: { configureClicked: _vm.configureClicked }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }