<template>
  <CCard class="mb-0 rule-widget-main" style="min-width: 200px">
    <CRow class="no-gutters">
      <CCol sm="12" md="3" xs="12" class="border-right rule-info-container">
        <CCardBody color="" class="">
          <div class="d-flex">
            <h5 class="">{{ name_ }}</h5>
          </div>

          <div>
            <CBadge :color="ruleTopicColor" class="rule-topic">
              {{topics_}}
            </CBadge>
          </div>

          <div class="d-flex mt-1" >
            <CSwitch
              variant="3d"
              type="checkbox"
              color="info"
              :v-c-tooltip="alertEnabled_? `Click to Mute Alerts` : `Click to Enable Alerts`"
              :checked.sync="alertEnabled_"
              @update:checked="updateAlertEnableField"
              class="mt-1"
            >
            </CSwitch>

            <BellBadge
              :enabled="alertEnabled_"
            >
            </BellBadge>
          </div>

          <div class="align-right pt-2" style="text-align: left">
            <CButton
              variant="outline"
              color="info"
              class="align-right flex-item"
              @click="configureClicked"
            >
              <CIcon name="cil-settings"></CIcon>&nbsp;Configure
            </CButton>
          </div>
        </CCardBody>
      </CCol>

      <CCol xs="12" sm="12" md="9" lg="9" class="border-right">
        <CCardBody>
          <CRow class="no-gutters justify-content-between">
            <CCol xs="4" sm="4" md="4" class="rule-route d-flex flex-column">
              <div class="info">
                <span>{{applyToNDeviceGroups}}</span>
                <br>
                <small class="text-muted">Description: {{description_}} </small>
              </div>
              <div class="rule-orb mt-auto">
                <img
                  src="images/lightning-bolt-100.png"
                  alt="..."
                  class="rounded-circle border border-info"
                />
              </div>
            </CCol>
            
            <CCol xs="4" sm="4" md="4" class="rule-route d-flex flex-column">
              <span> {{logicNaturalLanguage_}} </span>
              <div class="rule-orb mt-auto">
                <img
                  src="images/process-96.png"
                  alt="..."
                  class="rounded-circle border border-info"
                />
              </div>
            </CCol>

            <CCol xs="4" sm="4" md="4" class="rule-route d-flex flex-column">
              <div class="info">
                <span> Notification Method: {{alertType_}}</span>
                <br>
                <span> # of recipients: {{alertRecipients_.length}}</span>
              </div>
              <div class="rule-orb mt-auto">
                <img
                  :src="alertActionImg"
                  alt="..."
                  class="rounded-circle border border-info"
                />
              </div>
            </CCol>
          </CRow>

          <div class="d-none d-sm-block" style="margin-top: -40px; width: 80%;">
            <hr
              style="
              height: 2px;
              margin-left: 100px; 
              border-width: 0;
              color: gray;
              background-color: gray;
            "
            />
          </div>
        </CCardBody>

        <!-- End Card Container Row -->
      </CCol>
    </CRow>
  </CCard>
  <!-- End Card -->
</template>



<style scoped>
.rule-widget-container {
  border-radius: 5px;
  width: 100%;
}

.rule-widget-container {
  column-count: 1;
}

.rule-widget-main {
  min-width: 150px;
}

.rule-widget {
  min-width: 100px;
}

.rule-info-container {

  /*background-color: var(--danger)*/
  border-color: var(--warning);
  text-align: left;
}

/*
.rule-route-display{
  background-color: var(-light);
}*/

#rule-tool-panel {
  padding: 5px;
}

.rule-orb {
  height: 50px;
  text-align: center;
}

.rule-orb img{
    background-color: var(--light-gray);
    border-color: "border-info";
}

.rule-route {
  text-align: center;
}

.rule-route img {
  height: 50px;
  
}

.rule-topic {
    display: inline-block;
    height: 20px;
    padding: 5px;
}
</style>


<script>
import { mapGetters } from 'vuex'
import BellBadge from "@/components/rules/BellBadge";

export default {
  name: "RuleCard",
  components: {
    BellBadge,
  },
  props: {
    currUID: String,
  },
  data() {
    return {
      enabled_: false, 
      name_: "",
      description_: "",
      topics_: "",
      deviceGroups_uids_: [],
      logicString_: "",

      alertUID_: "",
      alertEnabled_: false,
      alertType_: "",
      alertRecipients_: [],
    };
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts',
    }),

    rules () {
      try {
        return this.$store.getters["rules"].filter(rule => {
          return rule.branch_id == this.$store.getters.selectedBranch.branch_id;
        })
      } catch (err) {
        return [];
      }
    },

    rule() {
      if (!this.rules || !this.rules.length) {
        return null;
      }
      return this.rules.find(item => {
        return item.uid == this.currUID;
      })
    },

    alert () {
      if (!this.rule || !this.alerts || !this.alerts.length) {
        return null;
      }
      return this.alerts.find(alert => {
        return alert.uid == this.rule.alert_uid;
      })
    },

    alertActionImg () {
      switch (this.alertType_) {
        case "sms":
          return "images/sms-96.png";
        case "email":
          return  "images/send-email-100.png";
        default:
          return "images/sms-96.png";
      }
    },

    ruleTopicColor () {
      switch (this.topics_) {
        case "Wand":
          return "info";
        case "Sensors":
          return "primary";
        default: 
          return "info";
      }
    },

    applyToNDeviceGroups () {
      let count = this.deviceGroups_uids_.length;
      if (!count) {
        return "Not apply to any device group"
      }
      if (count == 1) {
        return `Applies to 1 device group`;
      }
      return `Applies to ${this.deviceGroups_uids_.length} device groups`
    },
  },
  mounted() {},
  methods: {
    populateRulesFields() {
      if (!this.rule) {
        return;
      } 
      this.enabled_ = this.rule.enabled;
      this.name_ = this.rule.name;
      this.description_ = this.rule.description;
      this.topics_ = this.rule.topics;
      this.deviceGroups_uids_ = this.rule.deviceGroups_uids;
      this.logicNaturalLanguage_ = this.rule.logicNaturalLanguage;
    },

    populateAlertsFields() {
      if (!this.alert) {
        return;
      }
      this.alertUID_ = this.alert.uid;
      this.alertEnabled_ = this.alert.enabled;
      this.alertType_ = this.alert.action;
      this.alertRecipients_ = this.alert.recipientsArray;
    },

    configureClicked() {
      this.$emit("configureClicked", this.currUID);
    },

    async updateAlertEnableField (bool) {
      if (bool) {
        await this.$app.enableAlert(this.alertUID_);
      } else {
        await this.$app.disableAlert(this.alertUID_);
      }
    }
  },
  watch: {
    rule: {
      immediate: true,
      handler () {
        this.populateRulesFields();
      }
    },
    alert: {
      immediate:  true,
      handler () {
        this.populateAlertsFields();
      }
    }
  }
};
</script>