<template>
  <CRow>
    <CCol md="12">
      <CardLayout>
        <template #header>
          <CIcon name="cil-bell"> </CIcon>
            Rule Engine
        </template>

        <template>
          <CRow>
            <CCol xs="5" sm="5">
              <CInput placeholder="Search for a Rule"                 
                :value.sync="searchValue"
                lazy
              >
                <template #append>
                  <CButton color="info">
                    <CIcon name="cil-magnifying-glass" /> Search
                  </CButton>
                </template>
              </CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12" md="12">
              <RuleTable
                :filteredRulesUIDs="filteredRulesUIDs"
                @configureClicked="setActiveUID"
                class="h-100"
                :searchValue="searchValue"
              >
              </RuleTable>
            </CCol>
          </CRow>
        </template>
      </CardLayout>
    </CCol>

    <CModal
      :show.sync="activeRuleUID"
      centered
      fade
      color=""
      border-color="secondary"
      title="Configure Rule"
      size="lg"
    >
      <template #body-wrapper>
        <RuleConfig
            v-if="activeRuleUID"
            class="m-0 p-0"
            :options="options"
            :activeUID="activeRuleUID"
            :flag_update="flag_updateRule"
            @validationStatusChanged="ruleValidationStatusChanged"
          >
        </RuleConfig>
      </template>

      <template #footer>
        <div>
          <CButton @click="onModalCanceled"> Cancel </CButton>
          <CButton color="success" @click="onModalConfirmed" :disabled="!ruleConfig_validated"> Save </CButton>
        </div>
      </template>
    </CModal>

    <CToaster :autohide="2500">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          color="info"
          header="Action Cannot Be Completed "
        >
          Sorry, You cannot Add a new Rule because Rule Engine is in
          <strong> Preview Mode Only </strong>
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<style scoped>
#rule-container {
  min-height: 800px;
  height: 100%;
}

.sensors {
  color: white;
  background-color: firebrick;
}
</style>


<script>
import RuleTable from "@/components/rules/RuleTable";
import RuleConfig from "@/components/rules/RuleConfig";
import ThemeMixin from "@/mixins/ThemeMixin"; 
import CardLayout from "@/layouts/CardLayout";

import { mapGetters } from 'vuex'

export default {
  name: "RuleListView",
  components: {
    RuleTable,
    RuleConfig,
    CardLayout,
  },
  props: {
  },
  mixins:[ThemeMixin],
  data() {
    return {
      activeRuleUID: null,
      canEdit: false,
      fixedToasts: 0,
      fields: [],
      flag_updateRule: 0,

      options: [
        {
          class: "sensors",
          value: "1",
          label: "Sensors",
        },

        { value: "2", label: "Radio" },
        { value: "3", label: "Wand" },
        { value: "4", label: "Location" },
        { value: "5", label: "Events" },
      ],

      ruleConfig_validated: false,

      searchValue: "",
    };
  },

  computed: {
    rules () {
      try {
        return this.$store.getters["rules"].filter(rule => {
          return rule.branch_id == this.$store.getters.selectedBranch.branch_id;
        })
      } catch (err) {
        return [];
      }
    },
    devices() {
      return this.$app.activeDevices.map((item) => {
        return { id: item.device, value: item.name };
      });
    },

    ruleUIDs () {
      if (this.rules) {
        return this.rules.map(rule => {
          return rule.uid;
        })
      }
      return [];
    },

    filteredRulesUIDs () {
      return this.ruleUIDs;
    },
  },
  methods: {
    setActiveUID (uid) {
      return this.activeRuleUID = uid;
    },

    onModalCanceled () {
      this.activeRuleUID = null;
    },

    onModalConfirmed () {
      this.flag_updateRule = (this.flag_updateRule + 1) % 2; // to trigger child watcher.
      this.$nextTick(() => { // update first, then nullify the active rule.
        this.activeRuleUID = null;
      }) 
    },
    
    addRuleClick(e) {
      if (!this.canEdit) this.fixedToasts++;
    },

    ruleValidationStatusChanged (bool) {
      this.ruleConfig_validated = bool;
    }
  },

  async mounted() {
    console.log("Loading Rules");
    await this.$app.loadRules();
    await this.$app.loadAlerts();
    await this.$app.loadDeviceGroups();
  },
};
</script>
