var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex section-title" }, [
      _c(
        "span",
        {
          staticClass: "section-title-text",
          staticStyle: { "margin-right": "5px" }
        },
        [_vm._v(" Alert Configuration")]
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("CSwitch", {
            staticClass: "m-1",
            attrs: {
              variant: "3d",
              type: "checkbox",
              color: "info",
              "v-c-tooltip": _vm.enabled_
                ? "Click to Mute Alerts"
                : "Click to Enable Alerts",
              checked: _vm.enabled_
            },
            on: {
              "update:checked": [
                function($event) {
                  _vm.enabled_ = $event
                },
                _vm.updateEnableField
              ]
            }
          }),
          _c("BellBadge", { attrs: { enabled: _vm.enabled_ } })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", { staticClass: "subsection-label" }, [_vm._v("Action")]),
        _c("CSelect", {
          attrs: {
            horizontal: "",
            value: _vm.action_,
            options: _vm.actions_options,
            placeholder: "Select an action"
          },
          on: {
            "update:value": function($event) {
              _vm.action_ = $event
            }
          }
        }),
        _c("div", [
          _vm.emailMode
            ? _c(
                "div",
                [
                  _c("label", { staticClass: "subsection-label" }, [
                    _vm._v(" Email Recipients ")
                  ]),
                  _c("CTextarea", {
                    attrs: {
                      placeholder:
                        "abcd@gmail.com, cdef123@hotmail.com, 123hijk@gmail.com",
                      horizontal: "",
                      value: _vm.emailsString_,
                      rows: "2",
                      isValid: !_vm.$v.emailsString_.$invalid,
                      invalidFeedback: _vm.$v.emailsString_.$message
                    },
                    on: {
                      "update:value": function($event) {
                        _vm.emailsString_ = $event
                      }
                    }
                  })
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("label", { staticClass: "subsection-label" }, [
                    _vm._v("Text Recipients")
                  ]),
                  _c("RecipientsList", {
                    attrs: { recipientsList_: _vm.recipientsList_ },
                    on: {
                      editRecipient: _vm.editRecipient,
                      addRecipient: _vm.addRecipient,
                      deleteRecipient: _vm.deleteRecipient
                    }
                  })
                ],
                1
              )
        ]),
        _c("AlertMessageEditor", {
          attrs: {
            message_: _vm.message_,
            customMessageMode_: _vm.customMessageMode_
          },
          on: {
            messageChanged: _vm.updateMessage,
            customMessageModeChanged: _vm.updateCustomMessageMode
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }