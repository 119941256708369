<template>
  <CCard class="m-0">
    <CCardBody>
      <CDataTable
        :items="tableItems"
        :fields="fields"
        :items-per-page="20"
        hover
        pagination
        :cleaner="false"
        
        :striped="true"
        :table-filter="false"
        :tableFilterValue="searchValue"
        
        sorter
        style="font-size: 14px"
      >
        <template #name="{ item }">
          <RuleCard
            class="w-100 mb-2"
            :key="item.uid"
            :currUID="item.uid"
            @configureClicked="configureClicked"
          >
          </RuleCard>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from 'vuex'
import RuleCard from "@/components/rules/RuleCard";

export default {
  name: "RuleTable",
  components: {
    RuleCard,
  },
  props: {
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: ''},
        ]
      }
    },
    filteredRulesUIDs: {
      type: Array,
    },
    searchValue: {
      type: String,
    }, 
  },
  data() {
    return {
      sorterValue: { column: null, asc: false }, 
    }
  },
  methods: {
    configureClicked(uid) {
      this.$emit('configureClicked', uid);
    },
  },
  computed: {
    rules () {
      try {
        return this.$store.getters["rules"].filter(rule => {
          return rule.branch_id == this.$store.getters.selectedBranch.branch_id;
        })
      } catch (err) {
        return [];
      }
    },
       
    tableItems () {
      if (this.rules) {
        return this.rules.filter(rule => {
          return this.filteredRulesUIDs.includes(rule.uid);
        }).map(rule => {
          return {
            name: rule.name,
            uid: rule.uid,
          }
        })
      }
      return [];
    },
  },
};
</script> 