var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "items-list" },
    [
      _vm._l(_vm.recipientsList_, function(value, uid) {
        return _c("RecipientCard", {
          key: uid,
          staticClass: "item",
          attrs: {
            blockedEdit: _vm.hasActiveEditOrAdd,
            uid: uid,
            content: value.content,
            isActive: _vm.activeEditUID == uid
          },
          on: {
            select: function($event) {
              return _vm.onEditSelect(uid)
            },
            confirm: function(newContent) {
              return _vm.onEditConfirm(uid, newContent)
            },
            cancel: _vm.onEditCancel,
            delete: function($event) {
              return _vm.onDeleteConfirm(uid)
            }
          }
        })
      }),
      !_vm.hasRecipients
        ? _c("span", [_vm._v(" No Text Recipient At The Moment. ")])
        : _vm._e(),
      _vm.isAdding
        ? _c("RecipientCard", {
            staticClass: "item",
            attrs: { isActive: true, content: "" },
            on: { confirm: _vm.onAddConfirm, cancel: _vm.onAddCancel }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end item" },
        [
          !_vm.hasActiveEditOrAdd
            ? _c(
                "CButton",
                {
                  staticClass: "float-right",
                  attrs: { color: "success" },
                  on: { click: _vm.onAddSelect }
                },
                [_vm._v(" Add ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }