var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CFormLabel", {
        staticClass: "subsection-label",
        attrs: { label: "Message", tooltip: _vm.tooltipText }
      }),
      _c("CColl"),
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          staticClass: "form-check-input",
          attrs: {
            type: "radio",
            name: "messageMode",
            id: "defaultMsg",
            value: "default"
          },
          domProps: { checked: !_vm.customMessageMode_ },
          on: { click: _vm.messageRadioOnClick }
        }),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: "defaultMsg" } },
          [
            _vm._v(" " + _vm._s(_vm.defaultMessageText) + " "),
            _c("strong", { staticClass: "strong-text" }, [
              _vm._v("(Recommended)")
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          staticClass: "form-check-input",
          attrs: {
            type: "radio",
            name: "messageMode",
            id: "customMsg",
            value: "custom"
          },
          domProps: { checked: _vm.customMessageMode_ },
          on: { click: _vm.messageRadioOnClick }
        }),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: "customMsg" } },
          [_vm._v(" Send Your Own Custom Message ")]
        )
      ]),
      _vm.customMessageMode_
        ? _c("CTextarea", {
            attrs: {
              placeholder: "Enter a Brief Alert Message...",
              horizontal: "",
              value: _vm.message_,
              rows: "2",
              isValid: _vm.isMessageValid,
              invalidFeedback: _vm.invalidFeedback
            },
            on: { input: _vm.textboxOnInput }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }